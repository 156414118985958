import React, { useEffect, useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"
import Pagination from "../../Global/pagination"

const ListTimeclock = ({
  timeclocks,
  isLoading,
  loadData,
  setFormData,
  selectContact,
  states,
  setStates,
}) => {
  const formatDate = dateTimeString => {
    const date = new Date(dateTimeString)
    const options = { weekday: "short", month: "short", day: "numeric", year: "numeric" }

    let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date)

    const day = date.getDate()
    const suffix = getDaySuffix(day)

    return formattedDate.replace(day, `${day}${suffix}`)
  }

  const getDaySuffix = day => {
    if (day > 3 && day < 21) return "th" // Applies to 11-20
    switch (day % 10) {
      case 1:
        return "st"
      case 2:
        return "nd"
      case 3:
        return "rd"
      default:
        return "th"
    }
  }

  const formatTimeSlot = (dateTimeIn, dateTimeOut) => {
    const timeIn = new Date(dateTimeIn)
    const timeOut = new Date(dateTimeOut)

    const options = { hour: "numeric", minute: "numeric", hour12: true }

    const formattedTimeIn = new Intl.DateTimeFormat("en-US", options).format(timeIn)
    const formattedTimeOut = new Intl.DateTimeFormat("en-US", options).format(timeOut)

    return `${formattedTimeIn} - ${formattedTimeOut}`
  }

  const deleteTimeclock = async (e, item) => {
    e.preventDefault()
    try {
      const response = await AxiosInstance.delete(`/timeclock/${item.id}`)
      if (response.status === 200) {
        loadData(1)
        return
      }
      console.error("Unexpected response status: ", response.status)
    } catch (error) {
      console.error("Error when fetching timeclock list: ", error)
    }
  }

  const calculateDuration = timeclock => {
    const start = new Date(timeclock.dateTimeIn)
    const end = new Date(timeclock.dateTimeOut)

    const diffInMs = end - start

    const diffInMinutes = diffInMs / (1000 * 60)

    const hours = Math.floor(diffInMinutes / 60)
    const minutes = diffInMinutes % 60

    let result = ""
    if (hours > 0) {
      result += `${hours}h`
    }
    if (minutes > 0) {
      result += `${minutes}m`
    }

    return result || "0m"
  }

  const editTimeclock = (e, tc) => {
    e.preventDefault()
    const dateTimeIn = new Date(new Date(tc.dateTimeIn).getTime())
    const dateTimeOut = new Date(new Date(tc.dateTimeOut).getTime())

    let totalMin = (dateTimeOut - dateTimeIn) / (1000 * 60)
    let timeType = "Minutes"

    if (totalMin % 60 === 0) {
      totalMin /= 60
      timeType = "Hours"
    }

    const formData = {
      ...tc,
      dateTimeIn,
      dateTimeOut,
      description: tc.taskDoneJson.join("\n"),
      totalMin,
      timeType,
      quickAddName: "edited",
    }
    setFormData(formData)
    selectContact(tc.contact)
  }

  const onSearchBlur = e => {
    loadData(1)
  }

  return (
    <>
      <div className="row tabularMenuContainer">
        <div className="col">
          <div className="input-group filterDropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="bi bi-bullseye"></i>
            </button>
            <ul className="dropdown-menu">
              <li>
                <span className="dropdown-item-text">== Saved Filtered Views ==</span>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <i className="bi bi-bullseye"></i> Option 1
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <i className="bi bi-bullseye"></i> Option 2
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  <i className="bi bi-bullseye"></i> Option 3
                </a>
              </li>
              <li>
                <a className="dropdown-item createNewFilterView" href="#">
                  <i className="bi bi-plus"></i> Create New Filtered View
                </a>
              </li>
            </ul>
            <input
              type="search"
              className="form-control tableSearch"
              placeholder="Search"
              value={states.searchTerm}
              onChange={e => setStates({ ...states, searchTerm: e.target.value })}
              onBlur={onSearchBlur}
            />
          </div>
        </div>
        <div className="col-sm-9 d-none">
          <div className="float-end">
            <ul className="nav nav-tabs swiftCloudTabs" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="clientTimesheet-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#clientTimesheet"
                  role="tab"
                  aria-controls="clientTimesheet"
                  aria-selected="false"
                >
                  <span data-bs-toggle="tooltip" title="" data-bs-original-title="Clients">
                    <i className="bi bi-person-fill"></i> <span className="d-none d-md-inline">Clients</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="clientTimesheet"
              role="tabpanel"
              aria-labelledby="clientTimesheet-tab"
            >
              <table id="tblClientTimesheet" className="table dataTable" cellSpacing="0" width="100%">
                <thead>
                  <tr>
                    <th width="5%" className="text-center">
                      <span
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Client"
                        aria-label="Client"
                      >
                        <i className="bi bi-person-fill"></i>
                      </span>
                    </th>
                    <th width="25%">Date</th>
                    <th width="47%" className="d-none d-sm-table-cell">
                      Notes
                    </th>
                    <th width="8%" className="text-center">
                      <i className="bi bi-clock"></i>
                    </th>
                    {/*<th width="20%" className="text-end d-none d-lg-table-cell d-none">
                      <i className="bi bi-currency-dollar"></i>
                    </th>*/}
                  </tr>
                  <tr className="d-none table_action_container">
                    <td colSpan="5" className="text-start">
                      <div className="batch-action-container">
                        <button className="btn btn-trash">
                          <i className="bi bi-trash-fill"></i> Move to Trash
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr className="d-none filter_table_action_container">
                    <td colSpan="5" className="">
                      <div className="filter-batch-action-container">
                        <div className="row">
                          <div className="col-lg-9 col-sm-12">
                            {/* <form id="filterViewForm" className="filterViewRowForm"> */}
                            <div className="row row-cols-sm-auto g-2 align-items-center mb-3">
                              <div className="col-12">Show me</div>
                              <div className="col-12">
                                <div className="dropdown helpDeskTaskFilter">
                                  <button
                                    className="btn btn-default dropdown-toggle"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-person-fill"></i> Contacts
                                  </button>
                                  <ul className="dropdown-menu">
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <i className="bi bi-person-fill"></i> Contacts
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <i className="bi bi-check2-square"></i> Task / Job / Sales
                                        Opportunities
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <i className="bi bi-chat-dots-fill"></i> Messages &amp; Notifications
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <i className="bi bi-calendar-check"></i> Calendar Events
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <i className="bi bi-card-list"></i> Accounting Records
                                      </a>
                                    </li>
                                    <li>
                                      <a className="dropdown-item" href="#">
                                        <i className="bi bi-card-list"></i> Agreements
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-12">that are</div>
                              <div className="col-12">
                                <select className="form-select inputFeedType" name="options[]">
                                  <option value="opt_1">Tagged with...</option>
                                  <option value="opt_3">Search Results Include</option>
                                  <option value="ScheduledInto">Scheduled Into...</option>
                                </select>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-lg-12">
                                <div className="filterOpts" id="newfilter_1">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="columnFilter opt_1">
                                        <div className="row">
                                          <div className="col-lg-12 sortbytag_row">
                                            <div className="input-group mb-3">
                                              <select
                                                name="FV_sortbytag_with_or_without"
                                                id="FV_sortbytag_with_or_without"
                                                className="form-select"
                                              >
                                                <option value="Tagged with">Tagged with</option>
                                                <option value="Not Tagged with">Not Tagged with</option>
                                              </select>
                                              <div className="bootstrap-tagsinput bootstrap-tagsinput-max">
                                                <span className="tag label label-info">
                                                  tag<span data-role="remove"></span>
                                                  <span data-role="drag"></span>
                                                </span>
                                                <input type="text" placeholder="" />
                                                <button className="btn btn-sm btn-primary btnSaveTag">
                                                  <i className="fa fa-save"></i>
                                                </button>
                                              </div>
                                              <input
                                                type="text"
                                                name="FV_sortbytag_input"
                                                id="FV_sortbytag_input"
                                                className="form-control"
                                                defaultValue="tag"
                                                style={{ display: "none" }}
                                              />
                                              <select
                                                name="FV_sortbytag_score_opt"
                                                id="FV_sortbytag_score_opt"
                                                className="form-select"
                                              >
                                                <option value="Any">Any TagScore</option>
                                                <option value="Above">TagScore Above</option>
                                                <option value="Below">TagScore Below</option>
                                              </select>
                                              <input
                                                type="number"
                                                name="FV_sortbytag_score_input"
                                                id="FV_sortbytag_score_input"
                                                className="form-control d-none"
                                                placeholder="Value"
                                                defaultValue="50"
                                              />
                                              <select name="FV_sortbytag_asc_desc" className="form-select">
                                                <option value="Ascending">Ascending</option>
                                                <option value="Descending">Descending</option>
                                                <option value="Newest">Newest</option>
                                                <option value="Oldest">Oldest</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-lg-12 mb-3 FV_sortbytag_search_within_row display-none">
                                            <input
                                              type="text"
                                              name="FV_sortbytag_search_within"
                                              id="FV_sortbytag_search_within"
                                              placeholder="Search Within Results"
                                              className="form-control"
                                            />
                                            <a
                                              href="#"
                                              className="btnRemoveSearchWithinTag"
                                              data-bs-toggle="tooltip"
                                              title=""
                                              data-bs-original-title="Remove search"
                                              aria-label="Remove search"
                                            >
                                              <i className="bi bi-x"></i>
                                            </a>
                                          </div>
                                          <div className="col-lg-12 FV_addsortbytag_search_within_row">
                                            <button
                                              type="button"
                                              className="btnAddNewGreenDashed btnAddSortbyTagFilerCondition"
                                              data-bs-toggle="tooltip"
                                              title=""
                                              data-bs-original-title="Add filter condition"
                                              aria-label="Add filter condition"
                                            >
                                              <i className="bi bi-plus-lg"></i>
                                            </button>
                                            <button
                                              type="button"
                                              className="btnAddNewGreenDashed btnAddSortbyTagSearchCondition"
                                              data-bs-toggle="tooltip"
                                              title=""
                                              data-bs-original-title="Search"
                                              aria-label="Search"
                                            >
                                              <i className="bi bi-search"></i>
                                            </button>
                                            <div className="hidden_FV_sortbytag_filter_condition_row display-none">
                                              <div className="input-group mb-3">
                                                <select
                                                  name="sortbytagSearchCondition"
                                                  className="form-select"
                                                >
                                                  <option value="OR">OR Tag...</option>
                                                  <option value="AND">AND Tag...</option>
                                                  <option value="NOT">NOT Tag...</option>
                                                </select>
                                                <input
                                                  type="text"
                                                  className="form-control FV_sortbytagSearchTaginput"
                                                />
                                              </div>
                                              <a
                                                href="#"
                                                className="btnRemoveSortbyTagFilter"
                                                data-bs-toggle="tooltip"
                                                title=""
                                                data-bs-original-title="Remove filter"
                                                aria-label="Remove filter"
                                              >
                                                <i className="bi bi-x-lg"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="columnFilter opt_3 d-none">
                                        <input
                                          type="text"
                                          name="search_keyword"
                                          defaultValue=""
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="columnFilter ScheduledInto d-none">
                                        <select className="form-control form-select">
                                          <option value="All Calendars">All Calendars (Blended View)</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group text-center mb-0 filteredViewBtnGroup">
                              <button type="button" className="btn btn-default btnCloseFilteredView">
                                <i className="bi bi-x-lg"></i> Cancel
                              </button>
                              <button
                                className="btn btn-secondary btnSaveFilteredView"
                                data-toggle="modal"
                                data-target="#newFilterModal"
                              >
                                <i className="bi bi-check2"></i> Save Filtered View{" "}
                                <i className="bi bi-bullseye"></i>
                              </button>
                              <button className="btn btn-primary" id="viewFilterResult">
                                <i className="bi bi-check2"></i> View Results{" "}
                                <i className="bi bi-bullseye"></i>
                              </button>
                              <button className="btn btnDeleteFilterView display-none">
                                <i className="bi bi-trash-fill"></i> Delete Filtered View
                              </button>
                            </div>
                            {/* </form> */}
                          </div>
                          <div className="col-lg-3 col-sm-12 savedFilterdView mt-3 mt-lg-0">
                            <h4>Saved Filtered Views</h4>
                            <ul id="savedFilteredList">
                              <li>
                                <div className="position-relative d-inline-block">
                                  <a href="#" className="btn btn-default">
                                    <i className="bi bi-folder-fill"></i> Real estate listing
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    className="editFiltered"
                                    data-bs-original-title="Edit"
                                    aria-label="Edit"
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    className="deleteFilter"
                                    data-bs-original-title="Delete"
                                    aria-label="Delete"
                                  >
                                    <i className="bi bi-trash-fill"></i>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="position-relative d-inline-block">
                                  <a href="#" className="btn btn-default">
                                    <i className="bi bi-check2"></i> Plumber Transaction
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    className="editFiltered"
                                    data-bs-original-title="Edit"
                                    aria-label="Edit"
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    className="deleteFilter"
                                    data-bs-original-title="Delete"
                                    aria-label="Delete"
                                  >
                                    <i className="bi bi-trash-fill"></i>
                                  </a>
                                </div>
                              </li>
                              <li>
                                <div className="position-relative d-inline-block">
                                  <a href="#" className="btn btn-default">
                                    <i className="bi bi-person-fill"></i> Doctors Appointment
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    className="editFiltered"
                                    data-bs-original-title="Edit"
                                    aria-label="Edit"
                                  >
                                    <i className="bi bi-pencil-square"></i>
                                  </a>
                                  <a
                                    href="#"
                                    data-bs-toggle="tooltip"
                                    title=""
                                    className="deleteFilter"
                                    data-bs-original-title="Delete"
                                    aria-label="Delete"
                                  >
                                    <i className="bi bi-trash-fill"></i>
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {timeclocks.map(timeclock => {
                    return (
                      <tr key={`timeclock-${timeclock.id}`}>
                        <td className="text-center tableColChkDel">
                          <div className="tableColChkDelBox">
                            <input
                              type="checkbox"
                              name="fileid[]"
                              id={`TC_ID${timeclock.id}`}
                              className="css-checkbox"
                            />
                            <label htmlFor={`TC_ID${timeclock.id}`} className="css-checkbox-label"></label>
                            <span className="tableColChkSpan">
                              <img
                                src={`${process.env.GATSBY_WRANGLER_URL}/${
                                  timeclock?.contact?.profilePic || "StaticImages/avatar.gif"
                                }`}
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <p className="taskTime">{formatDate(timeclock.dateTimeIn)}</p>
                          <p className="taskTimeSlot">
                            {formatTimeSlot(timeclock.dateTimeIn, timeclock.dateTimeOut)}
                          </p>
                        </td>
                        <td className="d-none d-sm-table-cell">
                          {timeclock.taskDoneJson.map((task, index) => (
                            <div key={index}>{task}</div> // Or use <span> with style={{display: 'block'}}
                          ))}
                        </td>
                        <td className="text-end d-none d-lg-table-cell">
                          <div className="lastActivityBox">{calculateDuration(timeclock)}</div>
                          <div className="statusActionBox">
                            <a
                              href="#"
                              className="btn btn-edit"
                              data-bs-toggle="tooltip"
                              title=""
                              onClick={e => editTimeclock(e, timeclock)}
                              data-bs-original-title="Edit"
                              aria-label="Edit"
                            >
                              <i className="bi bi-pencil-fill"></i>
                            </a>
                            <a
                              href="#"
                              className="btn btn-delete"
                              data-bs-toggle="tooltip"
                              title=""
                              data-bs-original-title="Delete"
                              aria-label="Delete"
                              onClick={e => deleteTimeclock(e, timeclock)}
                            >
                              <i className="bi bi-trash-fill"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <Pagination
                currentPage={states.currentPage}
                pageCount={states.pageCount}
                onChange={loadData}
                totalItems={states.totalItems}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListTimeclock
