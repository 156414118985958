import * as React from "react"
import PrivateRoute from "../components/privateRoute"
import Timeclock from "../components/Calendar/Timeclock/timeclock"
import ReduxWrapper from "../redux/reduxWrapper"

const TimeClock = props => {
  return <PrivateRoute component={Timeclock} location={props.location} />
}

const WrappedPage = props => <ReduxWrapper element={<TimeClock {...props} />} />
export default WrappedPage
